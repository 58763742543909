<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Štítky - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete odstranit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Odstranit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.ser_id"
        :columns="columns"
        :loading="loading"
        :dataSource="sticker"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="ser_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/ecommerce/sticker/detail/'+value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <div slot="color" slot-scope="record" :style="'line-height:1.7;float:left;background-color:' + record.color + ';color: #fff !important;padding: 0 10px;font-size: 11px !important;margin-bottom: 5px;text-transform: uppercase;display: block;border-radius: 4px;-webkit-border-radius: 4px;-moz-border-radius: 4px;z-index: 1;'">{{record.languages[0].pivot.label}}</div>
        <div slot="color_locale" slot-scope="record">
          {{ localeColor(record.color) }}
        </div>
        <span slot="action" slot-scope="record">
          <router-link :to="'/ecommerce/sticker/detail/'+record.ser_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.ser_id])" okText="Potvrdit" cancelText="Zrušit">
            <template slot="title">
              <p>Opravdu si přejete odstranit položku: #{{ record.ser_id }} ?</p>
            </template>
            <a-button icon="delete" size="small">Odstranit</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ sticker.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      searchText: '',
      loaded: [],
      loading: false,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/ecommerce/sticker/add',
          title: 'Přidat',
        },
        {
          icon: 'sort-ascending',
          theme: 'outlined',
          to: '/ecommerce/sticker/sort',
          title: 'Seřadit',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'ser_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.ser_id - b.ser_id,
          scopedSlots: {
            customRender: 'ser_id',
          },
        },
        {
          title: 'Označení',
          dataIndex: 'languages[0].pivot.label',
          sorter: (a, b) => a.languages[0].pivot.label.localeCompare(b.languages[0].pivot.label),
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Pozice',
          dataIndex: 'position',
          sorter: (a, b) => a.position - b.position,
          scopedSlots: {
            customRender: 'text',
          },
        },
        {
          title: 'Barva pozadí',
          dataIndex: '',
          sorter: (a, b) => this.localeColor(a.color).localeCompare(this.localeColor(b.color)),
          scopedSlots: {
            customRender: 'color_locale',
          },
        },
        {
          title: 'HEX',
          dataIndex: 'color',
          sorter: (a, b) => a.color.localeCompare(b.color),
          scopedSlots: {
            customRender: 'text',
          },
        },
        {
          title: 'Náhled',
          dataIndex: '',
          scopedSlots: {
            customRender: 'color',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
    sticker: function () {
      return this.$store.getters['sticker/currentLanguage'](this.searchText)
    },
    colors: function () {
      return this.$store.getters['sticker/colors']
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            if (this.selectedRowKeys.length === 0) {
              this.sticker.forEach(item => {
                if (item.children !== undefined) {
                  this.selectedRowKeys = this.selectedRowKeys.concat(item.ser_id).concat(item.children.map(x => x.ser_id))
                } else {
                  this.selectedRowKeys.push(item.ser_id)
                }
              })
            } else {
              this.selectedRowKeys = []
            }
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('sticker/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.loaded.push(this.language)
        })
    },
    confirmDelete(sticker) {
      this.loading = true
      this.$store.dispatch('sticker/delete', sticker)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = sticker.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !sticker.includes(x)))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
    localeColor(color) {
      const a = this.colors.find(item => item.hex === color)
      return a === undefined || a === null ? 'nenalezena' : a.name
    },
  },
  created() {
    if (this.sticker.length === 0 && this.$store.getters['language/active'] !== null) {
      this.handleRefresh()
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (newValue !== null && !this.loaded.includes(this.language)) {
        this.handleRefresh()
      }
    },
  },
}
</script>
